import styled, { css } from 'styled-components'
import { yPad, xPad } from '../utils/theme'
import { Link } from 'gatsby'
import { darken } from 'polished'

interface IContainerProps {
  medium?: boolean
  padY?: boolean
  bg?: string
  shadow?: boolean
  full?: boolean
  image?: string
}

export const MainContent = styled.div`
  ${yPad};
  ${xPad};

  article {
    width: 70%;
    display: none;

    .gatsby-image-wrapper {
      margin-top: 0.5rem;
      margin-bottom: 2rem;
      > div {
        width: 70% !important;
        padding-bottom: 46.55% !important;
      }
    }

    img {
      width: 70% !important;
      height: auto !important;
      border-radius: 4px;

      &.img-sponsor {
        width: 60px !important;
        margin-right: 20px;
      }
    }
  }
  aside {
    margin-right: 5%;
    div {
      display: flex;
      border-radius: 4px;
      flex-direction: column;
      background-color: ${props => props.theme.colors.primary};
      float: left;
      color: #fff;
      padding: 30px;
      strong {
        color: #fff;
        font-size: 1.1rem;
        margin-bottom: 10px;
      }
      span {
        cursor: pointer;
        margin-bottom: 4px;
        transition: opacity 200ms ease-in-out;
        &.active {
          font-weight: 600;
          text-decoration: underline;
        }
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }

  section {
    display: flex;
    margin-top: 50px;
  }

  h3,
  h4 {
    color: ${props => props.theme.colors.primary};
  }
`

export const GallerySection = styled.section`
  padding-top: 100px;
  h2,
  p {
    text-align: center;
  }
  p {
    max-width: 800px;
    margin: 20px auto 0;
  }
`

export const Container = styled<IContainerProps, any>('div')`
  width: 100%;
  position: relative;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  max-width: ${props =>
    props.medium ? '1000px' : props.full ? '100%' : '1360px'};
  margin: 0 auto;

  background-color: ${props => props.bg || 'none'};
  box-shadow: ${props =>
    props.shadow ? '0 8px 18px rgba(0,0,0,0.1)' : 'none'};

  ${props =>
    props.padY &&
    css`
      ${yPad};
    `};

  ${props =>
    props.image &&
    css`
      background-color: ${props.theme.colors.dark};
      background-size: cover;
      background-position: center;
      background-image: url(${props.image});
      h1,
      h2,
      h3,
      h4,
      h5,
      p {
        color: #fff;
      }
    `};

  @media (min-width: 600px) {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  @media (min-width: 940px) {
    padding-left: 3rem;
    padding-right: 3rem;
  }
`

export const Hero = styled.header`
  position: relative;
  padding-top: 7rem;
  padding-bottom: 3.5rem;
  background-color: #fff;

  @media (min-width: 600px) {
    padding-top: 9rem;
    padding-bottom: 4.5rem;
  }
  @media (min-width: 940px) {
    padding: 10rem 0;
    display: flex;
    align-items: center;
  }
  @media (min-width: 1180px) {
    padding: 12rem 0;
  }
  @media (min-width: 1440px) {
    padding: 14rem 0;
  }
`

export const Content = styled.div`
  max-width: 850px;
  width: 90%;
  margin: 50px auto 50px;
  h1,
  h2 {
    color: ${props => props.theme.colors.dark};
    font-size: 3rem;
    margin-bottom: 20px;
    i {
      color: ${props => props.theme.colors.primary};
      font-style: normal;
    }
  }
  span {
    font-size: 22px;
    line-height: 1.4;
    margin: 1rem 0 2rem;
    display: block;
  }
  p > a,
  h4 > a {
    text-decoration: underline;
  }
  h4 {
    font-weight: 400;
    color: ${props => darken(0.1, props.theme.colors.primary)};
    font-size: 23px;
  }
`

export const HeroContent = styled.div`
  position: relative;

  @media (max-width: 939px) {
    margin: 0 auto;
    max-width: 520px;
  }

  @media (min-width: 940px) {
    display: flex;
    align-items: center;
  }
`

export const HeroMain = styled.div`
  position: relative;
  z-index: 10;
  max-width: 580px;

  @media (max-width: 939px) {
    margin-bottom: 1.5rem;
  }
  @media (min-width: 940px) {
    width: 55%;
  }
  @media (min-width: 1440px) {
    width: 50%;
  }

  h1 {
    margin-bottom: 0.35em;
  }

  p {
    font-size: 1.15em;
    margin-bottom: 2em;

    @media (min-width: 940px) {
      max-width: 80%;
      font-size: 1.25em;
    }
  }
`

interface IHeroFigureProps {
  shadow?: boolean
}

export const HeroFigure = styled<IHeroFigureProps, any>('figure')`
  @media (min-width: 940px) {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 53%;
    max-width: 600px;
  }
  @media (min-width: 1440px) {
    width: 53%;
    max-width: 700px;
  }
`

export const Cards = styled.div`
  display: flex;
  flex-wrap: wrap;

  /* Card */
  > div {
    width: 100%;
  }

  @media (min-width: 940px) {
    /* Card */
    > div {
      width: 33.33333%;
    }
  }
`

interface ISplitSection {
  alternate?: boolean
  padY?: boolean
}

export const SplitSection = styled<ISplitSection, any>('div')`
  text-align: center;

  ${props =>
    props.alternate &&
    css`
      flex-direction: row-reverse;
      @media (min-width: 1180px) {
        margin-right: -10%;
        margin-left: 0 !important;
      }
      @media (min-width: 1440px) {
        margin-right: -12%;
        margin-left: 0 !important;
      }
    `};

  ${props =>
    props.padY &&
    css`
      ${yPad};
    `};

  ul {
    text-align: left;
  }

  h4 {
    font-size: 1.2rem;
    color: ${props => props.theme.colors.primary};
  }

  @media (max-width: 939px) {
    padding: 0 3%;
    margin-bottom: 5rem;
  }
  @media (min-width: 940px) {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    text-align: left;
  }
  @media (min-width: 1180px) {
    margin-left: -10%;
  }
  @media (min-width: 1440px) {
    margin-left: -12%;
  }
`

export const SplitFigure = styled.div`
  width: 280px;
  ${xPad};
  img {
    border-radius: 16px;
    box-shadow: 0 8px 18px rgba(0, 0, 0, 0.1);
  }

  @media (min-width: 600px) {
    width: 380px;
  }
  @media (min-width: 940px) {
    width: ${props => (props.smallImage ? '300px' : '470px')};
  }
  @media (max-width: 939px) {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3rem;
  }
  @media (min-width: 1180px) {
    width: ${props => (props.smallImage ? '360px' : '630px')};
  }
  @media (min-width: 1440px) {
    width: ${props => (props.smallImage ? '400px' : '700px')};
  }
`

export const SplitContent = styled.div`
  max-width: 420px;

  ul {
    li {
      text-transform: lowercase;
    }
  }

  @media (min-width: 1180px) {
    max-width: 480px;
  }
  @media (max-width: 939px) {
    margin: 0 auto;
  }
`

interface ITextProps {
  size?: 'large'
}

export const Text = styled<ITextProps, any>('p')`
  ${props =>
    props.size === 'large' &&
    css`
      font-size: 1.2em;
      line-height: 1.8;
    `};
`

export const ReadMore = styled(Link)`
  display: block;
  padding-top: 1rem;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  color: ${props => props.theme.colors.purple};
`

export const Announcement = styled(Link)`
  margin-bottom: 1.5rem;
  display: flex;
  max-width: 350px;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 1.5rem;
  border-radius: 30px;
  background-color: #f1f8fe;
  color: #333;
  transition: background-color 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
`

interface IServiceCardProps {
  color: string
}

export const ServiceCard = styled<IServiceCardProps, any>('div')`
  background-color: #fff;
  padding: 1.5rem;
  border-top: 3px solid ${props => props.theme.colors[props.color]};
  box-shadow: 0 6px 18px 0 rgba(46, 50, 128, 0.15);
  border-radius: 4px;

  margin-bottom: 2rem;

  &:last-child {
    margin-bottom: 0;
  }

  @media (min-width: 940px) {
    display: flex;
    align-items: center;
    padding: 2rem;
    border-width: 4px;

    p {
      max-width: 80%;
    }
  }

  @media (max-width: 639px) {
    img {
      margin-bottom: 1rem;
    }
  }

  img {
    margin-right: 3rem;
    width: 112px;
    height: 112px;
  }
`
