import * as React from 'react'
import styled, { css } from 'styled-components'
import { xPad, yPad, yMargin } from '../utils/theme'
import Accordion from './Accordion'
import { darken } from 'polished'
import Button from './Button'
import { Link } from 'gatsby'

interface IFAQContainerProps {
  flushTop: boolean
}

export const FAQContainer = styled<IFAQContainerProps, any>('div')`
  background-color: #f9f8f7;
  ${yMargin};
  margin-bottom: 0 !important;

  h3 i {
    color: ${props => props.theme.colors.primary};
    font-style: normal;
  }

  ${props =>
    props.background &&
    css`
      /* background-image: url(${props.background}); */
      background-color: #171717;
      background-size: cover;
      background-repeat: none;
      background-position: center center;
      h3,
      button,
      p {
        color: #fff !important;
      }
    `};

  ${props =>
    props.flushTop &&
    css`
      margin-top: 0 !important;
    `};
`

export const FAQs = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  margin: 0 auto;
  max-width: 1000px;

  ${yPad};
  ${xPad};
  a {
    text-decoration: underline;
  }

  h3 {
    color: ${props => darken(0.4, props.theme.colors.primary)};
    &:not(:first-child) {
      padding-top: 2em;
    }
  }
`

interface IFAQ {
  title?: string
  answer?: string
  question?: string
  isHtml?: boolea
}

interface IFAQProps {
  flushTop?: boolean
  title: string
  data: IFAQ[]
}

const data = [
  {
    question: 'Parinity',
    answer: 'Financial professionals'
  }
]

export default ({ title, faqs, button, background, ...rest }: IFAQProps) => {
  const info: IFAQ[] = faqs || data
  return (
    <FAQContainer background={background} {...rest}>
      <FAQs>
        <h3>{title}</h3>
        {info.map(faq => {
          if (faq.answer && faq.question) {
            return (
              <Accordion
                answer={faq.answer}
                key={faq.question}
                question={faq.question}
                isHtml={faq.isHtml}
              />
            )
          }
        })}
        {button && (
          <Link to="/contact">
            <Button
              small={true}
              style={{ marginTop: 30 }}
              outline={true}
              color="#fff"
            >
              request a consultation
            </Button>
          </Link>
        )}
      </FAQs>
    </FAQContainer>
  )
}
