import * as React from 'react'
import Layout from '../components/Layout'
import { Button } from '../components/Button'
import {
  Text,
  Cards,
  Container,
  SplitContent,
  SplitSection,
  SplitFigure,
  ReadMore
} from '../components/styles'
import styled, { css } from 'styled-components'
import { xPad, yPad } from '../utils/theme'
import Contact from '../components/Contact'
import Card from '../components/Card'
import CTACard from '../components/CTACard'

import Img from 'gatsby-image'
import { graphql, Link } from 'gatsby'
import { Helmet } from 'react-helmet'
import Arrow from '../components/Arrow'
import CTA from '../components/CTA'
import { Flex, Box } from 'grid-styled'
import Form from '../components/Form'
import FAQs from '../components/FAQs'

import Hero from '../components/Hero'
import ContentCard from '../components/ContentCard'
import BackgroundSection from '../components/BackgroundSection'

import Testimonials from '../components/Testimonials'
import { allTestimonials } from './testimonials'

const CardsContainer = styled(Container)`
  margin-bottom: 50px;
  margin-top: 100px;
  h2 {
    text-align: center;
    font-size: 3.5rem;
    color: #171717;
    margin-bottom: 3rem;
  }

  > div {
    justify-content: space-around;
    @media (min-width: 940px) {
      > div {
        width: 31%;
      }
    }
  }
`

export const Video = styled.iframe`
  margin-top: 25px;
  width: 90vw;
  height: 50.75vw;
  max-height: 576px;
  max-width: 1024px;
  border-radius: 8px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.08);
`

const Logos = styled.div`
  display: flex;
  background: #ffffff;
  box-shadow: 0 14px 20px 0 rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  width: 900px;
  height: 100px;
  justify-content: space-evenly;
  align-items: center;
  margin: -50px auto 0;
  z-index: 99;
  position: relative;

  @media (max-width: 900px) {
    width: 95%;
  }

  @media (max-width: 600px) {
    .gatsby-image-outer-wrapper:last-child {
      display: none;
    }
  }

  @media (max-width: 500px) {
    display: none;
  }

  .gatsby-image-outer-wrapper {
    width: 80px;
    height: auto;
  }
`

const Images = styled.div`
  display: flex;
  margin: 30px 0 50px;
  justify-content: center;
  img,
  .gatsby-image-outer-wrapper {
    width: 250px;
    height: auto;
  }

  @media (max-width: 450px) {
    flex-direction: column;
    align-items: center;
  }
`

export const VideoContainer = styled.section`
  width: 100%;
  overflow: hidden;
  margin: 80px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @media (max-width: 900px) {
    margin-top: 15rem;
  }

  section {
    width: 100vw;
    display: flex;
    justify-content: space-evenly;
    margin-top: 50px;
    figure {
      width: calc(100vw / 8);
    }
  }

  h1 {
    margin-top: 100px;
    font-weight: 700;
    font-size: 5rem;
    color: ${props => props.theme.colors.dark};

    i {
      font-style: normal;
      color: ${props => props.theme.colors.primary};
    }
  }

  article {
    margin-top: 30px;
    width: 80%;
    max-width: 840px;
    color: #333;
    text-align: left;
    font-size: 1.1rem;

    i {
      font-size: 1.5em;
      font-style: normal;
      color: ${props => props.theme.colors.primary};
    }
  }
`

export const HomeHeader = styled.header`
  height: 600px;
  padding-top: 9rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  color: #fff;
  position: relative;

  display: flex;
  justify-content: space-around;
  align-items: flex-start;

  span {
    font-size: 1.5rem;
    font-weight: 500;
    opacity: 0.85;
  }

  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 8rem;
    height: 650px;
    > div > button {
      display: none;
    }
    > div:nth-child(2) {
      margin-top: 4rem;
    }
    figure,
    .gatsby-image-wrapper {
      height: 650px;
    }
  }
  @media (max-width: 600px) {
    > div:nth-child(2) {
      margin-top: 8rem;
    }
  }
  @media (max-width: 480px) {
    > div:nth-child(2) {
      margin-top: 10rem;
    }
  }

  figure {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    ${props =>
      props.slant &&
      css`
        transform: skewY(-6deg);
        transform-origin: left top 0px;
      `}
    overflow: hidden;

    .gatsby-image-outer-wrapper {
      position: absolute !important;
      width: 85vw;
      bottom: -50px;
      right: 0;
    }

    .gatsby-image-wrapper {
      overflow: visible !important;
      img {
        height: 600px !important;
      }
    }
  }

  > div {
    > h2,
    > p {
      text-shadow: 0 0 25px rgba(0, 0, 0, 0.5);
    }
  }

  h2 {
    color: #fff;
    font-weight: 500;
    max-width: 700px;

    em {
      text-decoration: underline;
      font-weight: 700;
      font-style: normal;
    }
  }

  p {
    font-weight: 500;
    line-height: 150%;
    padding-right: 2rem;
    max-width: 500px;
    margin: 1.5rem 0 2rem;
    opacity: 0.95;

    @media (max-width: 900px) {
      padding-right: 0;
    }
  }

  @media (min-width: 600px) {
    padding-top: 11rem;
    /* padding-left: 5rem; */
  }

  @media (min-width: 940px) {
    padding-top: 12rem;
  }
`

export const FormContainer = styled.div`
  width: 450px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 8px 18px rgba(0, 0, 0, 0.1);

  > div {
    padding: 1.5rem 2rem !important;
    border-top: 0;
  }
  h2 {
    color: #333 !important;
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  @media (max-width: 900px) {
    margin-top: 2rem;
  }

  @media (max-width: 480px) {
    width: 105%;
  }
`
export const AlternatingContent = styled.section`
  overflow: hidden;
  padding: 50px 0;

  > h2 {
    text-align: center;
    font-size: 3.5rem;
    color: ${props => props.theme.colors.dark};
    i {
      font-style: normal;
      color: ${props => props.theme.colors.primary};
    }
  }
`

const StyledBackgroundSection = styled(BackgroundSection)`
  min-height: 600px;
  background: #000;
  img {
    opacity: 0.3 !important;
  }
  @media (max-width: 500px) {
    height: auto;
    padding-bottom: 3rem;
  }
`

export const info = [
  {
    question: 'Short and long term',
    answer: `By understanding what is important to you, we offer strategies that remove complexity and support you to achieve your short and long term goals.`
  },
  {
    question: 'Tailored to you',
    answer: `We provide financial advice that is unique to you and support you to achieve the things that are most important to you, your family or your business. `
  },
  {
    question: 'Built on Partnerships',
    answer: `We are passionate about transparent and comprehensive financial advice because we believe that is a true financial advice relationship.`
  },
  {
    question: 'Holistic financial advice',
    answer: `We believe it is important to think of financial advice as broader than investing and insurance because it is all money related decisions that you make that contribute to your overall financial position. `
  },
  {
    question: 'Insight, Understanding & Clarity',
    answer: `We provide advice that is comprehensive and unique to you. We aim to give you financial confidence by understanding what truly motivates you and your financial decisions. We recommend the best financial solutions for you and then work with you to make them happen.`
  }
]

export const AboutContact = props => <Contact {...props} />

class Home extends React.Component {
  state = {
    word: 'unique'
  }
  // componentDidMount() {
  //   let i = 0
  //   const wordList = ['extraordinary', 'botique', 'tailored', 'insightful']
  //   setInterval(() => {
  //     this.setState({ word: wordList[i] })
  //     i += 1
  //     if (i > wordList.length) i = 0
  //   }, 5000)
  // }
  render() {
    const { data } = this.props
    return (
      <Layout header="dark">
        <Helmet>
          <title>
            Parinity Financial Professionals - Brisbane Financial Advisers
          </title>
          <meta
            name="description"
            content="Parinity is Brisbane's leading privately owned financial planning and advisory firm based in Camp Hill."
          />
        </Helmet>

        <VideoContainer>
          <h1>
            we create <i>{this.state.word}</i>
            <br /> financial solutions.
          </h1>

          <Video
            src="https://fast.wistia.net/embed/iframe/dvf2qzwu74?dnt=1&autoplay=false"
            frameBorder="0"
          />
          <article>
            <p>
              <i>
                We provide financial advice that is unique to you, in support of
                you!
              </i>
            </p>
            <p>
              We believe in delivering extraordinary solutions to inspire our
              clients. We achieve this by providing clarity through insight and
              partnering with you to support your financial decision-making and
              goals.
            </p>
            <p>
              As a privately owned practice, we create unique financial
              solutions for you and your family. Our team is based in Brisbane
              but with the marvels of technology we are able to support clients
              all over Australia and the World. We pride ourselves in being able
              to provide options that focus on each client’s financial
              motivations and decisions.
            </p>
            <p>
              We have the experience, knowledge and networks of specialist
              advisers to offer effective financial solutions.
            </p>
          </article>
        </VideoContainer>

        <CTA
          title="Download our wealth tracking app"
          subtitle="A better tracking solution."
          button="Access Parinity Wealthdesk"
          inverted={true}
          to="https://parinity.wealthdesk.com.au/my/home"
        />

        <AlternatingContent>
          <h2>our financial services</h2>
          <ContentCard
            image={data.patiosBrisbane.childImageSharp}
            title="medical professionals"
            text="We are a boutique planning firm and only partner with a select number of medical professionals each year to deliver tailored financial advice for those working in the health sector."
            buttons={[
              { text: 'find out more', to: '/medical-care-professionals/' }
            ]}
          />
          <ContentCard
            image={data.training.childImageSharp}
            title="personal advice"
            text="We explore all aspects of your financial life to understand what truly motivates you and your financial decisions. Our priority is to recommend the best financial solution for you and then work with you to make it happen. "
            buttons={[{ text: 'find out more', to: '/personal-advice/' }]}
          />
          <ContentCard
            image={data.offField.childImageSharp}
            title="mortgage advice"
            text="We don’t just provide our clients great rates on their home loans, we provide a comprehensive lending solution. Our process removes the complexity and allows us to compare ‘apples with apples’ so you can make an informed decision. "
            buttons={[{ text: 'find out more', to: '/mortgage-advice/' }]}
          />
          <ContentCard
            image={data.adam.childImageSharp}
            title="parinity f.i.r.e"
            text=" FIRE is about buying your time back and choosing freedom sooner rather than more money later.    There are many versions of F.I.R.E but all have a central theme. You
            want to create passive income so you aren’t working to live."
            buttons={[{ text: 'find out more', to: '/parinity-fire/' }]}
          />
        </AlternatingContent>

        <Testimonials testimonials={allTestimonials} />
        <CardsContainer>
          <h2>what sets us apart?</h2>
          <Cards>
            <Card
              color="primary"
              shadow={true}
              title="partnership"
              content="A formal agreement between two or more parties that have agreed to work together in the pursuit of common goals."
            />

            <Card
              color="secondary"
              shadow={true}
              title="insight"
              content="The ability to perceive clearly or deeply to understand ones own problems without recourse to trial-and-error behaviour."
            />

            <Card
              color="purple"
              shadow={true}
              title="clarity"
              content="Intelligence exactness and simplicity. Clearness or lucidity as to perception or understanding."
            />
          </Cards>
        </CardsContainer>
        <FAQs
          flushTop={true}
          faqs={info}
          title="our unique approach"
          button={true}
          background={data.whyUs.childImageSharp.fluid.src}
        />

        <AboutContact data={data} />
      </Layout>
    )
  }
}

export default Home

export const query = graphql`
  query HomePageQuery {
    jpBackground: file(relativePath: { eq: "jp-bg.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 95) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    whyUs: file(relativePath: { eq: "why-us-jp.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 95) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    shirts: allFile(filter: { relativeDirectory: { eq: "home-shirts" } }) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 700, toFormat: PNG) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    logos: allFile(filter: { relativeDirectory: { eq: "logos" } }) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    adam: file(relativePath: { eq: "image13.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    offField: file(relativePath: { eq: "mortgage-advice.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    patiosBrisbane: file(relativePath: { eq: "parinity-medical.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    training: file(relativePath: { eq: "personal-advice.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    drSellars: file(relativePath: { eq: "about-gfit.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    accessories: file(relativePath: { eq: "roofing-brisbane.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    blurredHero: file(relativePath: { eq: "cta-bg.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
